exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-art-js": () => import("./../../../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-posts-2023-08-28-in-the-beginning-index-md": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/posts/2023-08-28-in-the-beginning/index.md" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-posts-2023-08-28-in-the-beginning-index-md" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-posts-2023-09-18-url-change-and-obsidian-index-md": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/posts/2023-09-18-url-change-and-obsidian/index.md" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-posts-2023-09-18-url-change-and-obsidian-index-md" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-posts-2024-10-14-website-redesign-index-md": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/posts/2024-10-14-website-redesign/index.md" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-posts-2024-10-14-website-redesign-index-md" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-itg-js": () => import("./../../../src/pages/itg.js" /* webpackChunkName: "component---src-pages-itg-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-protogens-js": () => import("./../../../src/pages/protogens.js" /* webpackChunkName: "component---src-pages-protogens-js" */),
  "component---src-pages-tools-pronunciation-js": () => import("./../../../src/pages/tools/pronunciation.js" /* webpackChunkName: "component---src-pages-tools-pronunciation-js" */)
}

